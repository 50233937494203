// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-haltung-jsx": () => import("./../src/pages/haltung.jsx" /* webpackChunkName: "component---src-pages-haltung-jsx" */),
  "component---src-pages-herkunft-und-qualitaet-jsx": () => import("./../src/pages/herkunft-und-qualitaet.jsx" /* webpackChunkName: "component---src-pages-herkunft-und-qualitaet-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */)
}

